import React, { useEffect, useContext } from 'react';
import {HashRouter, Switch, Route} from 'react-router-dom';
import {PrivateRoute} from './PrivateRoute';
import { useSelector, useDispatch } from 'react-redux';
import { startGoogleLogin } from 'src/redux/actions/auth';
import { axiosAuthGetNoState } from 'src/helpers/axios/AxiosRequest';
import { GlobalContext } from 'src/context/GlobalContext';
import '../scss/style.scss';



const loading = (
    <div className="text-center mt-5">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
  )

// Containers
const TheLayout = React.lazy(() => import('../containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('../views/pages/login/Login'));
const GoogleLogin = React.lazy(() => import('../views/pages/login/GoogleLogin'));
const Page401 = React.lazy(() => import('../views/pages/page401/Page401'));
const Page404 = React.lazy(() => import('../views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('../views/pages/page500/Page500'));
const AutorizarPpto = React.lazy(() => import('../views/pages/abierto/AutorizarPpto'));
const PrintVersionVerLineas  = React.lazy(() => import('../views/finanzas/lineasdecredito/PrintVersionVerLineas'));
// Email App
const TesoreriaApp = React.lazy(() => import('../views/tesoreria/TesoreriaApp'));
const AdmonCtrlApp = React.lazy(() => import('../views/admonctrl/AdmonCtrlApp'));
//const PlanCtrlOpApp = React.lazy(() => import('../views/planctrloperacional/PlanCtrlOpApp'));
const FinanzasApp = React.lazy(() => import('../views/finanzas/FinanzasApp'));
const ComprasApp = React.lazy(() => import('../views/compras/ComprasApp'));
const ActivosFijosApp = React.lazy(() => import('../views/activosfijos/ActivosFijosApp'));
const ContabilidadApp = React.lazy(() => import('../views/contabilidad/ContabilidadApp'));
const JuridicoApp = React.lazy(() => import('../views/juridico/JuridicoApp'));
const AdmonProyectosApp = React.lazy(() => import('../views/admonproyectos/AdmonProyectosApp'));
const RealEstateApp = React.lazy(() => import('../views/realestate/RealEstateApp'));
//const CygApp = React.lazy(() => import('../views/cyg/CygApp'));
const EfpApp = React.lazy(() => import('../views/efp/EfpApp'));
const AyudaApp = React.lazy(() => import('../views/ayuda/AyudaApp'));

const ConfiguracionTIApp = React.lazy(() => import('../views/configuracionti/ConfiguracionTIApp'));
const AboutApp = React.lazy(() => import('../views/about/AboutApp'));

export const AppRouter = () => {
    const {isLoggedIn} = useSelector(state => state.auth);
    const lastPath = localStorage.getItem('lastPath') || '/dashboard';
    const dispatch = useDispatch();
    const {BACKEND_URL} = useContext(GlobalContext);
    useEffect(() => {
        // Check in store state
        if(isLoggedIn === false){
            // Page Reload? Check on LocalStorage
            const localSession = localStorage.getItem('isLoggedIn') || false;
            const token = localStorage.getItem('token') || '';
            
            if(localSession && token !== ''){
                //Obtenemos información adicional del usuario   
                const url= `${BACKEND_URL}/administracion/user`;
                let request = axiosAuthGetNoState(url, token);
                request.then(function(data){
                    //console.log(data);
                    //Vamos por roles
                    const urlRoles= `${BACKEND_URL}/administracion/users/myRoles/${data.data.data.id}`;
                    
                    let requestRoles = axiosAuthGetNoState(urlRoles, token);
                    requestRoles.then(function(dataRoles){
                       // console.log(dataRoles);
                        //Guardamos todo
                        dispatch(startGoogleLogin(data.data.data.name, token, data.data.data.avatar, data.data.data.email, data.data.data.uen_id, data.data.data.uen?.sociedad_co, dataRoles.data.data.roles));
                    });
                });
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);
    
//  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} isAuthenticated={isLoggedIn} />
//  <PrivateRoute path="/planctrloperacional" name="Plan y Ctrl Op" component={props => <PlanCtrlOpApp {...props} />} isAuthenticated={isLoggedIn} />
    return (
        <div>
            <HashRouter>
                <React.Suspense fallback={loading}>
                    <Switch>
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                    <Route exact path="/401" name="No autorizado" render={props => <Page401 {...props} />} />
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} lastPath={lastPath} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/about" name="About" component={props => <AboutApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/admonctrl" name="Admon Ctrol" component={props => <AdmonCtrlApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/tesoreria" name="Tesoreria App" component={props => <TesoreriaApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/admonproyectos" name="Administración de Proyectos" component={props => <AdmonProyectosApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/finanzas" name="Sector Financiero" component={props => <FinanzasApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/compras" name="Compras" component={props => <ComprasApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/activosfijos" name="Bienes y Activos Fijos" component={props => <ActivosFijosApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/juridico" name="Sector Juridico" component={props => <JuridicoApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/contabilidad" name="Contabilidad" component={props => <ContabilidadApp {...props} />} isAuthenticated={isLoggedIn} />
                    {/*<PrivateRoute path="/contabilidad" name="Contabilidad" component={props => <JuridicoApp {...props} />} isAuthenticated={isLoggedIn} /> */}
                    <PrivateRoute path="/efp" name="EFP" component={props => <EfpApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/ayuda" name="Ayuda y Soporte" component={props => <AyudaApp {...props} />} isAuthenticated={isLoggedIn} />
                    <PrivateRoute path="/configuracionti" name="Configuración TI" component={props => <ConfiguracionTIApp {...props} />} isAuthenticated={isLoggedIn} />
                    
                    <PrivateRoute path="/realestate" name="Real Estate" component={props => <RealEstateApp {...props} />} isAuthenticated={isLoggedIn} />
                    {/* <PrivateRoute path="/cyg" name="Concursar y Ganar" component={props => <CygApp {...props} />} isAuthenticated={isLoggedIn} /> */}
                    <Route exact path="/autorizarppto/:id" name="Espacio Abierto" render={props => <AutorizarPpto {...props} />} />
                    <Route exact path="/lineasdecredito/imprimir/:id" name="Imprimir" render={props => <PrintVersionVerLineas {...props} />} />
                    <Route path='/auth/google' render={props => <GoogleLogin {...props} />}/>
                    
                    {/* no poner nada debajo de esta ruta TODO ARRIBA  */}
                    <PrivateRoute path="/" name="Inicio" component={props => <TheLayout {...props} />} isAuthenticated={isLoggedIn} />
                    </Switch>
                </React.Suspense>
            </HashRouter>
        </div>
    )
}